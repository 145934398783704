import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent,TitleServices, TextServices, ListServices, BoxImages} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"


import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const dietetyka = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
    title="Dietetyka"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący zWsparcie żywieniowe w chorobach wątroby, nerek, trzustki, niewydolności serca, przy problemach kostno-stawowych oraz w okresie rekonwalescencji. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="dietetyka zwierząt kalisz"
    />
    
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Dietetyka</BigTitlePages>  
       
         <FlexContent>
          <UslugiPages />
 <TextServices style={{paddingLeft:'15%', paddingRight:'10%'}}> Właściwy sposób żywienia jest w stanie zapobiec chorobom żywieniowo zależnym, takim jak otyłość, zapalenie trzustki czy cukrzyca. Co wiecej dieta weterynaryjna jest wręcz koniecznością w przypadku istnienia schorzeń układu pokarmowego psa lub kota.
Ustalenie zbilansowanej diety skutecznie przyspiesza powrót czworonoga do pełnego zdrowia. W niektórych przypadkach zmiana sposobów żywienia może uratować życie choremu pupilowi.</TextServices>


<Flex>
 
    <BoxTextPages>

<TitleServices>Oferujemy:</TitleServices>
<TitleServices>Diety bytowe,</TitleServices>
<TitleServices>Weterynaryjne diety lecznicze:</TitleServices>
<ListServices> Ochrona dróg moczowych</ListServices>
<ListServices> Choroby skóry</ListServices>
<ListServices> Kontrola masy ciała</ListServices>
<ListServices> Schorzenia przewodu pokarmowego</ListServices>
<ListServices> Wsparcie żywieniowe w chorobach wątroby, nerek, trzustki, niewydolności serca, przy problemach kostno-stawowych oraz w okresie rekonwalescencji.</ListServices>


    </BoxTextPages>
<BoxImages>
<StaticImage
    src="../images/dietetyka.jpeg"
    alt="Dietetyka zwierząt Kalisz"
    width={600}

    placeholder="blurred"
    layout="constrained"
    className='staticimagepages'
    />
 
</BoxImages>
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default dietetyka